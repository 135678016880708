define("ember-modal-dialog/components/tether-dialog", ["exports", "@ember/component", "@ember/object", "@ember/string", "ember-modal-dialog/components/basic-dialog", "@ember/template-factory"], function (_exports, _component, _object, _string, _basicDialog, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.hasOverlay}}
    <EmberWormhole @to={{this.destinationElementId}}>
      <EmberModalDialog::Overlay
        class={{this.overlayClassNamesString}}
        @onClickOverlay={{this.onClickOverlay}}
      />
    </EmberWormhole>
  {{/if}}
  <EmberTether
    class={{this.containerClassNamesString}}
    @target={{this.tetherTarget}}
    @attachment={{this.attachment}}
    @targetAttachment={{this.targetAttachment}}
    @targetModifier={{this.targetModifier}}
    @classPrefix={{this.tetherClassPrefix}}
    @offset={{this.offset}}
    @targetOffset={{this.targetOffset}}
    @constraints={{this.constraints}}
    ...attributes
  >
    {{yield}}
  </EmberTether>
  */
  {
    "id": "+FJe/vje",
    "block": "[[[41,[30,0,[\"hasOverlay\"]],[[[1,\"  \"],[8,[39,1],null,[[\"@to\"],[[30,0,[\"destinationElementId\"]]]],[[\"default\"],[[[[1,\"\\n    \"],[8,[39,2],[[16,0,[30,0,[\"overlayClassNamesString\"]]]],[[\"@onClickOverlay\"],[[30,0,[\"onClickOverlay\"]]]],null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"]],[]],null],[8,[39,3],[[16,0,[30,0,[\"containerClassNamesString\"]]],[17,1]],[[\"@target\",\"@attachment\",\"@targetAttachment\",\"@targetModifier\",\"@classPrefix\",\"@offset\",\"@targetOffset\",\"@constraints\"],[[30,0,[\"tetherTarget\"]],[30,0,[\"attachment\"]],[30,0,[\"targetAttachment\"]],[30,0,[\"targetModifier\"]],[30,0,[\"tetherClassPrefix\"]],[30,0,[\"offset\"]],[30,0,[\"targetOffset\"]],[30,0,[\"constraints\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[18,2,null],[1,\"\\n\"]],[]]]]]],[\"&attrs\",\"&default\"],false,[\"if\",\"ember-wormhole\",\"ember-modal-dialog/overlay\",\"ember-tether\",\"yield\"]]",
    "moduleName": "ember-modal-dialog/components/tether-dialog.hbs",
    "isStrictMode": false
  });
  let TetherDialog = _exports.default = (_dec = (0, _object.computed)('targetAttachment'), _class = class TetherDialog extends _basicDialog.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "targetAttachment", null);
      _defineProperty(this, "attachment", null);
      _defineProperty(this, "tetherTarget", null);
    }
    init() {
      super.init(...arguments);
      this._ensureAttachments();
    }
    get targetAttachmentClass() {
      let targetAttachment = this.targetAttachment || '';
      // Convert tether-styled values like 'middle right' to 'right'
      targetAttachment = targetAttachment.split(' ').slice(-1)[0];
      return "ember-modal-dialog-target-attachment-".concat((0, _string.dasherize)(targetAttachment), " emd-target-attachment-").concat((0, _string.dasherize)(targetAttachment));
    }
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      this._ensureAttachments();
    }
    // element, css selector, view instance, 'viewport', or 'scroll-handle'

    get tetherClassPrefix() {
      return 'ember-tether';
    }
    set tetherClassPrefix(val) {
      if (val) {
        return val;
      }
      return 'ember-tether';
    }

    // offset - passed in
    // targetOffset - passed in
    // targetModifier - passed in
    _ensureAttachments() {
      if (!this.attachment) {
        (0, _object.set)(this, 'attachment', 'middle center');
      }
      if (!this.targetAttachment) {
        (0, _object.set)(this, 'targetAttachment', 'middle center');
      }
    }
  }, _applyDecoratedDescriptor(_class.prototype, "targetAttachmentClass", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "targetAttachmentClass"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "tetherClassPrefix", [_object.computed], Object.getOwnPropertyDescriptor(_class.prototype, "tetherClassPrefix"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TetherDialog);
});